<template>
	<div class="_w-max _pc-pad-x-280 _pc-pad-y-70 contact-index _mb-pad-x-25">
		<div class="contact-left">
			<img class="_w-max" src="http://api.kmaonan.com/uploads/attach/2023/06/20230625/0c5fb4d951a17f0a03346c7630e519c6.png" alt="">
		</div>
		<div class="contact-right">
			<div class="title _b _mb-mar-bottom-30">{{ $t('about_us.warehouse_address_title') }}</div>
			<div class="main">
				<div class="li li-1">
					<div class="title">
						<div class="title-left _mb-w-150 _mb-font-28">{{ $t('about_us.dizhi') }}</div>
						<div class="_display-none _mb-display-block _flex-1">
							<div class="info _mb-font-28 " style="word-break:break-all;" v-if="Islogin">{{ info.province+info.city+info.area+info.address }}{{ agent_info.warehouse_address||'' }}<span v-if="agent_info.is_show_no">{{ userInfo.province_name_alias}}_</span>{{userInfo.copy}}{{'_' + userInfo.user_code }}</div>
							<div class="info _mb-font-28 " style="word-break:break-all;" v-else>{{ 'xxxxxx' }}</div>
						</div>
						<div class="_flex-row-mid copy-info _mb-mar-left-35" @click="onCopy(agent_info.is_show_no ? info.province+info.city+info.area+info.address+agent_info.warehouse_address+'（'+userInfo.province_name_alias+'_'+userInfo.copy+'_'+userInfo.user_code+'）' : info.province+info.city+info.area+info.address+agent_info.warehouse_address+'（'+userInfo.copy+'_'+userInfo.user_code+'）')">
							<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30" style="" src="~@/assets/image/hangda/copy.png" alt="">
							<span class="_pc-font-18 _mb-display-none" style="color: #4284DC;">{{ $t('复制') }}</span>
						</div>
					</div>
					<div class="info _pc-font-18 _mb-display-none" style="line-height: 1.7;" v-if="Islogin">{{ info.province+info.city+info.area+info.address }}{{ agent_info.warehouse_address||'' }}<span v-if="agent_info.is_show_no">{{ userInfo.province_name_alias}}_</span>{{userInfo.copy}}{{'_' + userInfo.user_code }}</div>
					<div class="info _pc-font-18 _mb-display-none" style="line-height: 1.7;" v-else>{{ 'xxxxxx' }}</div>
				</div>
				<div class="li li-2">
					<div class="_flex-row-mid">
						<div class="_pc-font-20 _mb-font-28 _mb-w-150" style="color: #333;">{{ $t('联系电话') }}</div>
						<div class="_pc-font-32 _pc-mar-left-20 _mb-font-28 _mb-flex-1" style="color: #4284DC;word-break:break-all;" v-if="Islogin">{{ info.tel }}</div>
						<div class="_pc-font-32 _pc-mar-left-20 _mb-font-28 _mb-flex-1" style="color: #4284DC;word-break:break-all;" v-else>{{ 'xxxxxx' }}</div>
					</div>
					<div class="_flex-row-mid copy-info _mb-mar-left-35" @click="onCopy(info.tel)">
						<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30" src="~@/assets/image/hangda/copy.png" alt="">
						<span class="_pc-font-18 _mb-display-none" style="color: #4284DC;">{{ $t('复制') }}</span>
					</div>
				</div>
				<div class="li li-2">
					<div class="_flex-row-mid">
						<div class="_pc-font-20 _mb-font-28 _mb-w-150" style="color: #333;">{{ $t('recipient') }}</div>
						<div class="_pc-mar-left-20 _pc-font-20 _mb-font-28 _mb-flex-1" style="color: #999;line-height: 1.7;word-break:break-all;" v-if="Islogin"><span v-if="agent_info.is_show_no">{{ userInfo.province_name_alias}}_</span>{{userInfo.copy}}{{'_' + userInfo.user_code }}</div>
						<div class="_pc-mar-left-20 _pc-font-20 _mb-font-28 _mb-flex-1" style="color: #999;line-height: 1.7;word-break:break-all;" v-else>{{ 'xxxxxx' }}</div>
					</div>
					<div class="_flex-row-mid copy-info _mb-mar-left-35" @click="onCopy( agent_info.is_show_no ? userInfo.province_name_alias + '_' + userInfo.copy + '_' + userInfo.user_code : userInfo.copy + '_' + userInfo.user_code )">
						<img class="_pc-mar-right-10 _pc-w-15 _mb-w-30" src="~@/assets/image/hangda/copy.png" alt="">
						<span class="_pc-font-18 _mb-display-none" style="color: #4284DC;">{{ $t('复制') }}</span>
					</div>
				</div>
			</div>
			<div class="all-copy-btn" @click="Allcopy()">
				<img src="~@/assets/image/hangda/copy_white.png" alt="">
				<span class="_pc-mar-left-10">{{ $t('一键复制') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCookies } from "@/libs/util.js"
	import { storehouseInfo, getUserInfo, bannerList } from '@/api/jiyun';
	export default {
		name:'warehouseAddress',
		data() {
			return {
				info:{},
				userInfo:{},
				Islogin: false,
				agent_info: {}
			}
		},
		created(){
			this.storehouseInfo();
			let token = getCookies('web-token');
			if(token){
				this.getUserInfo();
			}else{
				this.Islogin = false;
			}
			this.bannerList();
		},
		methods: {
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 获取用户信息
			getUserInfo(){
				getUserInfo().then((res)=>{
					this.userInfo = res.data;
					console.log(this.userInfo)
					this.Islogin = true;
				}).catch((err)=>{
					this.Islogin = false;
				})
			},
			// 仓库地址
			storehouseInfo(){
				let param = {
					domain_url: window.location.origin
				}
				storehouseInfo(param).then((res)=>{
					this.info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			onCopy(text) {
				let that = this;
				if(this.Islogin){
					this.$copyText(text).then(function(e){
						that.$Message.success(that.$t('复制成功'))
					}),function(e){
						that.$Message.error(that.$t('复制失败'))
					}
				}else{
					this.$Message.info(this.$t('请登录'));
				}
			},
			copyError(e) {
				this.$Message.error(this.$t('复制失败'))
			},
			Allcopy:function () {
				let that = this;
				if(!that.Islogin) return that.$Message.info(that.$t('请登录'));
				let name = that.userInfo.province_name_alias;
				let two_name = that.agent_info.is_show_no ? name.split('').join(' ') : '';
				let temp = that.userInfo.copy + '_';
				two_name = two_name+temp;
				let tel = that.info.tel;
				let code = that.userInfo.user_code;
				let address = `${that.info.province}${that.info.city}${that.info.area}${that.info.address} ${that.agent_info.warehouse_address || ''}`;
				let text = `收件人：${name}\n联系电话：${tel}\n收货地址：${address}(${two_name}${code})\n`
				this.$copyText(text).then(function (e) {
					that.$Message.success(that.$t('复制成功'))
				}, function (e) {
					that.$Message.error(that.$t('复制失败'))
				})
			}
		}
	}
</script>

<style scoped>
	.contact-index {
		display: flex;
	}
	.contact-left {
		width: 585px;
		height: 464px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 45px;
	}
	.contact-right {
		flex: 1;
		min-height: 464px;
		padding: 8px 0px;
		/* border: 1px solid #eee; */
	}
	.contact-right .title {
		color: #333333;
		font-size: 32px;
	}
	.contact-right .main {
		padding: 10px 0px;
	}
	.contact-right .main .li {
		width: 100%;
		padding: 25px 0px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
	}
	.contact-right .main .li-1 .title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size: 20px;
	}
	.contact-right .main .li-1 .info {
		color: #999;
		font-size: 18px;
		margin-top: 16px;
	}
	.contact-right .main .li-1 .title .title-left {
		color: #333333;
	}
	.contact-right .main .li-1 .title .title-copy {
		color: #4284DC;
	}
	.contact-right .main .li-2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.contact-right .main .li:last-child {
		border-bottom: none;
	}
	.contact-right .all-copy-btn {
		min-width: 414px;
		height: 57px;
		border-radius: 	11px;
		background-color: #4284DC;
		color: #ffffff;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}
	.contact-right .all-copy-btn:hover {
		opacity: 0.4;
		cursor: pointer;
	}
	.contact-right .all-copy-btn img {
		width: 20px;
		height: 20px;
	}
	.copy-info:hover {
		opacity: 0.4;
		cursor: pointer;
	}


	@media screen and (max-device-width: 768px) {
		.contact-index{
			flex-direction: column;
		}
		.contact-left {
			width: 100%;
			height: unset;
			margin-right: 0px;
			order:1;
		}
		.contact-right {
			flex: 1;
			min-height: unset;
			padding: 3.04414vh 0px;
			/* border: 1px solid #eee; */
		}

		.contact-right .main{
			padding:  0;
		}

		.contact-right .title {
			font-size: 4.8vw;
		}

		.contact-right .main .li-1 .info{
			margin-top: 0;
			font-size: 3.73333vw;
		}

		.contact-right .all-copy-btn {
			min-width: unset;
			width: 100%;
			height: 5.70776vh;
			border-radius: 	6px;
			font-size: 3.73333vw;
		}
		.contact-right .all-copy-btn img {
			width: 4vw;
			height: unset;
		}
	}
</style>