<template>
	<div class="_w-max _pc-pad-x-280 contact-index _mb-pad-x-25">
		<div class="head-title">
			<div class="head-title-left">{{ $t('about_us.contact_info') }}</div>
			<div class="head-title-right">CONTACT DETAILS</div>
		</div>
		<div class="main-box">
			<div class="main-box-left">
				<div class="_pc-pad-y-30 main-box-left-li _mb-pad-y-25">
					<img src="~@/assets/image/hangda/shucai/contactItem1.png" alt="">
					<div class="info">
						<div class="name">{{ $t('about_us.contact_user') }}</div>
						<div class="content" v-if="lang=='zh_cn'">{{ agent_info.name }}</div>
						<div class="content" v-else>{{ agent_info.name_ru }}</div>
					</div>
				</div>
				<div class="_pc-pad-y-30 main-box-left-li _mb-pad-y-25">
					<img src="~@/assets/image/hangda/shucai/contactItem2.png" alt="">
					<div class="info">
						<div class="name">{{ $t('联系电话') }}</div>
						<div class="content">{{ agent_info.link_phone }}</div>
					</div>
				</div>
				<div class="_pc-pad-y-30 main-box-left-li _mb-pad-y-25">
					<img src="~@/assets/image/hangda/shucai/contactItem3.png" alt="">
					<div class="info">
						<div class="name">{{ $t('about_us.dizhi') }}</div>
						<div class="content">{{ agent_info.link_address }}</div>
					</div>
				</div>
			</div>
			<div class="main-box-right">
				<img src="~@/assets/image/hangda/shucai/shucai1.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import { getConfig, bannerList } from '@/api/jiyun';
	export default {
		name: 'contact',
		data() {
			return {
				official_address: '',
				official_nickname: '',
				official_phone: '',
				lang: 'ru',
				agent_info: {}
			}
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'ru';
			this.getConfig();
			this.bannerList();
		},
		methods: {
			// 获取系统配置
			getConfig(){
				getConfig().then((res)=>{
					this.official_address = res.data.official_address;
					this.official_nickname = res.data.official_nickname;
					this.official_phone = res.data.official_phone;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
		}
	}
</script>

<style scoped>
	.contact-index {
		background-image: url(../../../assets/image/hangda/contact_bg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.head-title {
		width: 100%;
		padding-top: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.head-title .head-title-left {
		font-size: 36px;
		color: #333;
		font-weight: bold;
	}
	.head-title .head-title-right {
		color: #AAAAAA;
		font-size: 16px;
		letter-spacing: 8px;
	}
	.main-box {
		padding: 25px 2px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;	
	}
	.main-box .main-box-left {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.main-box .main-box-left .main-box-left-li {
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
		width: 100%;
		display: flex;
		align-items: center;
	}
	.main-box .main-box-left .main-box-left-li:last-child {
		border-bottom: none;
	}
	.main-box .main-box-left .main-box-left-li img {
		margin-right: 40px;
		width: 78px;
		height: 78px;
		flex-shrink: 0;
	}
	.main-box .main-box-left .main-box-left-li .info {
		flex: 1;
	}
	.main-box .main-box-left .main-box-left-li .name {
		color: #333333;
		font-size: 20px;
		margin-bottom: 10px;
	}
	.main-box .main-box-left .main-box-left-li .content {
		color: #4284DC;
		font-size: 32px;
		font-weight: bold;
		line-height: 1.7;
	}
	.main-box .main-box-right {
		width: 595px;
		height: 380px;
		margin-left: 60px;
		flex-shrink: 0;
	}
	.main-box .main-box-right img {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-device-width: 768px) {
		.head-title{
			padding-top:3.04414vh;
		}
		
		.head-title .head-title-left{
			font-size: 4.8vw;
		}

		.head-title-right{
			display: none;
		}

		.main-box{
			padding: 2.66362vh 0.66667vw;
			display: block;
		}

		.main-box .main-box-left .main-box-left-li img{
			width: 10.66667vw;
			height: unset;
			margin-right:4vw;
		}

		.main-box .main-box-left .main-box-left-li .name{
			font-size: 3.73333vw;
			margin-bottom: 0;
		}

		.main-box .main-box-left .main-box-left-li .content{
			font-size: 5.33333vw;
			line-height: 1.5;
		}

		.main-box .main-box-right{
			margin-left: 0;
			height: unset;
			width: 100%;
		}
	}
</style>